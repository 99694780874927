import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/@vercel+analytics@1.1.0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_reac_nv5j2da45jczhjgfoyg7oqqcme/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_reac_nv5j2da45jczhjgfoyg7oqqcme/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/font/google/target.css?{\"path\":\"src/lib/io-kit/font.ts\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":\"normal\",\"variable\":\"--font-io\"}],\"variableName\":\"groteskFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AppHasLoaded"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/app/[locale]/AppHasLoaded.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/auth/browser/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/io-kit/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/app/[locale]/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsPendoInternal"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/pendo/server/browser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RerenderOnSubscriptionContext"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/subscriptions/index.tsx");
